import { createMuiTheme } from "@material-ui/core/styles";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import AllInclusive from "@material-ui/icons/AllInclusive";

import {
    constrainedTheme,
    lessConstrainedTheme,
    unconstrainedTheme,
    submitFormTheme,
} from "./components/Theme";
import { Strong } from "./components/Utilies";

const subscribe_link = "https://forms.gle/mtdhYJGBHEJpE41Q9"

const domains = [
    {
        name: "Monolingual",
        description: "",
        tasks: [
            {
                name: "ASR",
                description: "Automatic Speech Recognition is most commonly performed on a single language.\
                To facilitate this, models are evaluated on 14 different monolingual ASR tasks across 9 languages.\
                The languages are selected based on geographical and linguistic considerations to balance language and domain \
                coverage with manageable experimental mass.\
                The languages in this track are: English, French, German, Japanese, Mandarin Chinese, Mixtec, Russian, Swahili, and Swedish.\
                Performance is evaluated using Character Error Rate (CER), with the exception of Japanese and Chinese, which use Phoneme Error Rate (PER)."
            }
        ]
    },
    {
        name: "Multilingual",
        description: "",
        tasks: [
            {
                name: "ASR",
                description: "Multilingual ASR models leverage cross-lingual representations to simultaneously recognize speech from multiple languages.\
                Models are tasked to perform ASR on all 143 languages in ML-SUPERB. 20 of the 143 languages are reserved for the few-shot scenario, where only 5\
                utterances are made available for each of the few-shot languages during training. The remaining 123 languages have either 10 minutes or 1 hour of training data each,\
                depending on the variation of the Multilingual ASR task being performed.\
                Models are evaluated on Character Error Rate (CER)."
            },
            {
                name: "LID",
                description: "Language Identification is the task of classifying the language spoken in an utterance. Models are trained to identify utterances from all 143 languages.\
                Languages have either 10 minutes or 1 hour of training data each, depending on the variation of the Multilingual LID task being performed.\
                Submissions are evaluated using language identification accuracy."
            },
            {
                name: "ASR + LID",
                description: "Multilingual Speech Recognition and Language Identification can be performed jointly by appending a language tag to the beginning of each transcription.\
                Joint training can improve performance in certain scenarios, and it can also enhance model interpretability by separating language identification errors.\
                Models are trained to jointly perform both ASR and LID on all 143 languages simultaneously. Similar to the Multilingual ASR track, 20 of the 143 languages are reserved for the few-shot scenario.\
                Few-shot languages only have 5 utterances made available during training. The other 123 languages have either 10 minutes or 1 hour of training data each,\
                depending on the variation of the task being performed. \
                This task has two evaluation metrics: transcriptions are evaluated using Character Error Rate (CER), while language identification is evaluated using accuracy."
            }
        ]
    },
]

const domains_superb = [
    {
        name: "recognition",
        description: "recognition description",
        tasks: [
            {
                name: "PR",
                description:
                    "\
            Phoneme Recognition, PR transcribes an utterance into the smallest content units.\
            We include alignment modeling in the PR task to avoid the potential inaccurate forced alignment.\
            LibriSpeech train-clean-100/dev-clean/test-clean subsets are adopted in SUPERB for training/validation/testing.\
            Phoneme transcriptions are obtained from the LibriSpeech official g2p-model-5 and the conversion script in Kaldi librispeech s5 recipe.\
            The evaluation metric is phone error rate (PER).\
          ",
            },
            {
                name: "ASR",
                description:
                    "\
            Automatic Speech Recognition, ASR transcribes utterances into words.\
            While PR analyzes the improvement in modeling phonetics, ASR reflects the significance of the improvement in a real-world scenario.\
            LibriSpeech train-clean-100/devclean/test-clean subsets are used for training/validation/testing.\
            The evaluation metric is word error rate (WER).\
          ",
            },
        ],
    },
    {
        name: "detection",
        description: "detection description",
        tasks: [
            {
                name: "KS",
                description:
                    "\
            Keyword Spotting, KS detects preregistered keywords by classifying utterances into a predefined set of words.\
            The task is usually performed on-device for the fast response time.\
            Thus, accuracy, model size, and inference time are all crucial.\
            We choose the widely used Speech Commands dataset v1.0 for the task.\
            The dataset consists of ten classes of keywords, a class for silence, and an unknown class to include the false positive.\
            The evaluation metric is accuracy (ACC)\
          ",
            },
            {
                name: "QbE",
                description:
                    "\
            Query by Example Spoken Term Detection, QbE detects a spoken term (query) in an audio database (documents) by \
            binary discriminating a given pair of query and document into a match or not.\
            The English subset in QUESST 2014 challenge is adopted since we focus on investigating English as the first step.\
            The evaluation metric is maximum term weighted value (MTWV) which balances misses and false alarms.\
            In the SUPERB Challenge, the average between Mean Average Precision (MAP) and Equal Error Rate (ERR) is used as the metric on the hidden-set\
          ",
            },
        ],
    },
    {
        name: "semantics",
        description: "semantics description",
        tasks: [
            {
                name: "IC",
                description:
                    "\
            Intent Classification, IC classifies utterances into predefined classes to determine the intent of speakers.\
            We use the Fluent Speech Commands dataset, where each utterance is tagged with three intent labels: action, object, and location.\
            The evaluation metric is accuracy (ACC).\
          ",
            },
            {
                name: "SF",
                description:
                    "\
            Slot Filling, SF predicts a sequence of semantic slot-types from an utterance, \
            like a slot-type FromLocation for a spoken word Taipei, which is known as a slot-value.\
            Both slot-types and slot-values are essential for an SLU system to function.\
            The evaluation metrics thus include slot-type F1 score and slotvalue CER.\
            Audio SNIPS is adopted, which synthesized multi-speaker utterances for SNIPS.\
            Following the standard split in SNIPS, US-accent speakers are further selected for training, and others are for validation/testing.\
          ",
            },
            {
                name: "ST",
                description: `Speech Translation (ST) translates utterance into foreign words. To achieve this goal, the model has to perform ASR and MT simultaneously, which increases the difficulty. CoVoST2 En-De dataset is adopted while all the examples containing "REMOVE" are removed. The evaluation metric is case-sensitive detokenized BLEU. \
                In the SUPERB Challenge, since it is too difficult to train the ST model with limited translation pairs, we first train on CoVoST2 En-De dataset and then finetuned on the training set of the hidden-set.
                `
            }
        ],
    },
    {
        name: "speaker",
        description: "speaker description",
        tasks: [
            {
                name: "SID",
                description:
                    "\
            Speaker Identification, SID classifies each utterance for its speaker identity as a multi-class classification, \
            where speakers are in the same predefined set for both training and testing.\
            The widely used VoxCeleb1 [26] is adopted, and the evaluation metric is accuracy (ACC).\
          ",
            },
            {
                name: "SV",
                description:
                    "\
            Automatic Speaker Verification, ASV verifies whether the speakers of a pair of utterances match as a binary classification, \
            and speakers in the testing set may not appear in the training set.\
            Thus, ASV is more challenging than SID. VoxCeleb1 is used without VoxCeleb2 training data and noise augmentation. \
            The evaluation metric is equal error rate (EER).\
          ",
            },
            {
                name: "SD",
                description:
                    "\
            Speaker Diarization, SD predicts who is speaking when for each timestamp, and multiple speakers can speak simultaneously.\
            The model has to encode rich speaker characteristics for each frame and should be able to represent mixtures of signals.\
            LibriMix is adopted where LibriSpeech train-clean-100/dev-clean/test-clean are used to generate mixtures for training/validation/testing.\
            We focus on the two-speaker scenario as the first step.\
            The time-coded speaker labels were generated using alignments from Kaldi LibriSpeech ASR model.\
            The evaluation metric is diarization error rate (DER).\
          ",
            },
        ],
    },
    {
        name: "paralinguistics",
        description: "paralinguistics description",
        tasks: [
            {
                name: "ER",
                description:
                    "\
            Emotion Recognition, ER predicts an emotion class for each utterance.\
            The most widely used ER dataset IEMOCAP is adopted, and we follow the conventional evaluation protocol:\
            we drop the unbalance emotion classes to leave the final four classes with a similar amount of data points and \
            cross-validates on five folds of the standard splits.\
            The evaluation metric is accuracy (ACC).\
          ",
            },
        ],
    },
    {
        name: "generation",
        description: "generation description",
        tasks: [
            {
                name: "SE",
                description: `Speech enhancement (SE) is the task of removing background noise from a degraded speech signal and improving the perceived quality and intelligibility of the signal. In SUPERB, we evaluate the speech enhancement problem on the VoiceBank-DEMAND corpus. A three layer BLSTM model is trained to predict the spectral mask for the clean signal. The prediction is transformed back to the time domain using inverse short-time Fourier transform (iSTFT). Our evaluation metrics cover various aspects of the speech enhancement quality. including Perceptual Evaluation of Speech Quality (PESQ) and ShortTime Objective Intelligibility (STOI)
                `
            },
            {
                name: "SS",
                description: `Speech Separation (SS) is the task of separating target speech from background interference. It is an important step for speech processing, especially for noisy and multi-talker scenarios. In SUPERB, we investigate speech separation on the Libri2Mix dataset. We use the same 3-layer BLSTM model as the enhancement task, and permutation invariant training (PIT) is performed to optimize the objectives. The evaluation metric for speech separation is scale-invariant signal-to-distortion ratio improvement (SI-SDRi).
                `
            },
        ],
    }
];

const tracks = [
    {
        name: "constrained",
        rules: (
            <span>
                A fair comparison between{" "}
                <Strong>frozen representations</Strong> by enforcing the same
                downstream model in each task. Only a few hyper-paramters for
                training are allowed to tuned.
            </span>
        ),
        submit: "Make sure to read the rules before submitting.",
        Icon: LockIcon,
        theme: createMuiTheme(constrainedTheme),
    },
    {
        name: "less-constrained",
        rules: (
            <span>
                A comparison between <Strong>frozen representations</Strong>{" "}
                with customized but limited-resource downstream models. The
                details of downstream models are reported along with
                submissions.
            </span>
        ),
        submit: false,
        Icon: LockOpenIcon,
        theme: createMuiTheme(lessConstrainedTheme),
    },
    {
        name: "unconstrained",
        rules: (
            <span>
                The track does not limit any approach for solving SUPERB tasks
                as long as it in principle utilizes only{" "}
                <Strong>a single shared model</Strong> with limited
                task-specific parameters.
            </span>
        ),
        submit: false,
        Icon: AllInclusive,
        theme: createMuiTheme(unconstrainedTheme),
    },
];


const leaderboard_selections = [
    {
        name: "10m",
        theme: createMuiTheme(lessConstrainedTheme),
    },
    {
        name: "1h",
        theme: createMuiTheme(unconstrainedTheme),
    },
];

const public_hidden_selections = [
    {
        name: "public",
        theme: createMuiTheme(submitFormTheme),
    },
    {
        name: "hidden",
        theme: createMuiTheme(submitFormTheme),
    },
];

const public_task_columnInfo_10m = {
    ASR_mono_public_10m: {
        header: "Mono-ASR",
        width: 120,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    ASR_multi_norm_public_10m: {
        header: "Multi-ASR (Normal)",
        width: 180,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    ASR_multi_few_public_10m: {
        header: "Multi-ASR (Few-shot)",
        width: 180,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    LID_public_10m: {
        header: "LID",
        width: 80,
        higherBetter: true,
        isScore: true,
        type: "number",
    },
    ASR_LID_multi_norm_asr_public_10m: {
        header: "Multi-ASR + LID (Normal) CER",
        width: 240,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    ASR_LID_multi_norm__lid_public_10m: {
        header: "Multi-ASR + LID (Normal) Acc",
        width: 240,
        higherBetter: true,
        isScore: true,
        type: "number",
    },
    ASR_LID_multi_few_public_10m: {
        header: "Multi-ASR + LID (Few-shot)",
        width: 240,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
}

const public_task_columnInfo_1h = {
    ASR_mono_public_1h: {
        header: "Mono-ASR",
        width: 120,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    ASR_multi_norm_public_1h: {
        header: "Multi-ASR (Normal)",
        width: 180,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    ASR_multi_few_public_1h: {
        header: "Multi-ASR (Few-shot)",
        width: 180,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    LID_public_1h: {
        header: "LID",
        width: 80,
        higherBetter: true,
        isScore: true,
        type: "number",
    },
    ASR_LID_multi_norm_asr_public_1h: {
        header: "Multi-ASR + LID (Normal) CER",
        width: 240,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    ASR_LID_multi_norm__lid_public_1h: {
        header: "Multi-ASR + LID (Normal) Acc",
        width: 240,
        higherBetter: true,
        isScore: true,
        type: "number",
    },
    ASR_LID_multi_few_public_1h: {
        header: "Multi-ASR + LID (Few-shot)",
        width: 240,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
}

const hidden_task_columnInfo_10m = {
    ASR_mono_hidden_10m: {
        header: "Mono-ASR",
        width: 120,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    ASR_multi_norm_hidden_10m: {
        header: "Multi-ASR (Normal)",
        width: 180,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    LID_hidden_10m: {
        header: "LID",
        width: 80,
        higherBetter: true,
        isScore: true,
        type: "number",
    },
    ASR_LID_multi_norm_asr_hidden_10m: {
        header: "Multi-ASR + LID (Normal) CER",
        width: 240,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    ASR_LID_multi_norm__lid_hidden_10m: {
        header: "Multi-ASR + LID (Normal) Acc",
        width: 240,
        higherBetter: true,
        isScore: true,
        type: "number",
    },
}

const hidden_task_columnInfo_1h = {
    ASR_mono_hidden_1h: {
        header: "Mono-ASR",
        width: 120,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    ASR_multi_norm_hidden_1h: {
        header: "Multi-ASR (Normal)",
        width: 180,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    LID_hidden_1h: {
        header: "LID",
        width: 80,
        higherBetter: true,
        isScore: true,
        type: "number",
    },
    ASR_LID_multi_norm_asr_hidden_1h: {
        header: "Multi-ASR + LID (Normal) CER",
        width: 240,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    ASR_LID_multi_norm__lid_hidden_1h: {
        header: "Multi-ASR + LID (Normal) Acc",
        width: 240,
        higherBetter: true,
        isScore: true,
        type: "number",
    },
}

const hidden_task_columnInfo = {
    macs: {
        header: "MACs",
        width: 130,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    macsShort: {
        header: "(1)",
        width: 50,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    macsMedium: {
        header: "(2)",
        width: 50,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    macsLong: {
        header: "(3)",
        width: 50,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    macsLonger: {
        header: "(4)",
        width: 50,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    PR_per_hidden_dev: {
        header: "PR",
        width: 80,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    ASR_wer_hidden_dev: {
        header: "ASR",
        width: 80,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    ER_acc_hidden_dev: {
        header: "ER",
        width: 80,
        higherBetter: true,
        isScore: true,
        type: "number",
    },
    QbE_map_hidden_dev: {
        header: "QbE-MAP",
        width: 80,
        higherBetter: true,
        isScore: true,
        type: "number",
    },
    QbE_eer_hidden_dev: {
        header: "QbE-EER",
        width: 80,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    SID_acc_hidden_dev: {
        header: "SID",
        width: 80,
        higherBetter: true,
        isScore: true,
        type: "number",
    },
    SV_eer_hidden_dev: {
        header: "SV",
        width: 80,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    SD_der_hidden_dev: {
        header: "SD",
        width: 80,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    SE_stoi_hidden_dev: {
        header: "SE-STOI",
        width: 80,
        higherBetter: true,
        isScore: true,
        type: "number",
    },
    SE_pesq_hidden_dev: {
        header: "SE-PESQ",
        width: 80,
        higherBetter: true,
        isScore: true,
        type: "number",
    },
    SS_sisdri_hidden_dev: {
        header: "SS",
        width: 80,
        higherBetter: true,
        isScore: true,
        type: "number",
    },
    ST_bleu_hidden_dev: {
        header: "ST",
        width: 80,
        higherBetter: true,
        isScore: true,
        type: "number",
    },
    // test
    PR_per_hidden_test: {
        header: "PR",
        width: 80,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    ASR_wer_hidden_test: {
        header: "ASR",
        width: 80,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    ER_acc_hidden_test: {
        header: "ER",
        width: 80,
        higherBetter: true,
        isScore: true,
        type: "number",
    },
    QbE_map_hidden_test: {
        header: "QbE-MAP",
        width: 80,
        higherBetter: true,
        isScore: true,
        type: "number",
    },
    QbE_eer_hidden_test: {
        header: "QbE-EER",
        width: 80,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    SID_acc_hidden_test: {
        header: "SID",
        width: 80,
        higherBetter: true,
        isScore: true,
        type: "number",
    },
    SV_eer_hidden_test: {
        header: "SV",
        width: 80,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    SD_der_hidden_test: {
        header: "SD",
        width: 80,
        higherBetter: false,
        isScore: true,
        type: "number",
    },
    SE_stoi_hidden_test: {
        header: "SE-STOI",
        width: 80,
        higherBetter: true,
        isScore: true,
        type: "number",
    },
    SE_pesq_hidden_test: {
        header: "SE-PESQ",
        width: 80,
        higherBetter: true,
        isScore: true,
        type: "number",
    },
    SS_sisdri_hidden_test: {
        header: "SS",
        width: 80,
        higherBetter: true,
        isScore: true,
        type: "number",
    },
    ST_bleu_hidden_test: {
        header: "ST",
        width: 80,
        higherBetter: true,
        isScore: true,
        type: "number",
    },
}

const individual_submission_columnInfo = {
    aoeTimeUpload: {
        header: "Upolad Time",
        width: 160,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    submitName: {
        header: "Method",
        width: 150,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    modelDesc: {
        header: "Description",
        width: 100,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    modelURL: {
        header: "Model URL",
        width: 100,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    download: {
        header: "Download",
        width: 100,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    task: {
        header: "Track",
        width: 130,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    showOnLeaderboard: {
        header: "Show",
        width: 80,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    stride: {
        header: "Stride",
        width: 100,
        higherBetter: undefined,
        type: "number",
    },
    inputFormat: {
        header: "Input Format",
        width: 100,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    corpus: {
        header: "Corpus",
        width: 100,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    paramDesc: {
        header: "Parameter Description",
        width: 100,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    paramShared: {
        header: "Params",
        width: 130,
        higherBetter: false,
        type: "number",
    },
    macs: {
        header: "MACs",
        width: 130,
        higherBetter: false,
        type: "number",
    },
    macsShort: {
        header: "(1)",
        width: 130,
        higherBetter: false,
        type: "number",
    },
    macsMedium: {
        header: "(2)",
        width: 130,
        higherBetter: false,
        type: "number",
    },
    macsLong: {
        header: "(3)",
        width: 130,
        higherBetter: false,
        type: "number",
    },
    macsLonger: {
        header: "(4)",
        width: 130,
        higherBetter: false,
        type: "number",
    },
    fineTunedParam: {
        header: "Fine-tuned parameters",
        width: 100,
        higherBetter: undefined,
        type: "number",
    },
    taskSpecParam: {
        header: "Task-specific parameters",
        width: 100,
        higherBetter: undefined,
        type: "number",
    },
    state: {
        header: "State",
        width: 80,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    stateInfo: {
        header: "State Info",
        width: 100,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    submitUUID: {
        header: "Submission ID",
        width: 300,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    ...public_task_columnInfo_10m,
};

const individual_submission_hidden_columnInfo = {
    aoeTimeUpload: {
        header: "Upolad Time",
        width: 160,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    submitName: {
        header: "Method",
        width: 150,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    modelDesc: {
        header: "Description",
        width: 100,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    task: {
        header: "Track",
        width: 130,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    showOnLeaderboard: {
        header: "Show",
        width: 80,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    huggingfaceOrganizationName: {
        header: "Organization",
        width: 130,
        higherBetter: undefined,
        type: "alphanumeric"
    },
    huggingfaceRepoName: {
        header: "Repository",
        width: 130,
        higherBetter: undefined,
        type: "alphanumeric"
    },
    huggingfaceCommonHash: {
        header: "Commit",
        width: 130,
        higherBetter: undefined,
        type: "alphanumeric"
    },
    paramShared: {
        header: "Shared parameters",
        width: 100,
        higherBetter: undefined,
        type: "number",
    },
    state: {
        header: "State",
        width: 100,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    stateInfo: {
        header: "State Info",
        width: 100,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    submitUUID: {
        header: "Submission ID",
        width: 300,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    ...hidden_task_columnInfo,
};


const leaderboard_columnInfo = {
    submitName: {
        header: "Method",
        width: 150,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    modelURL: {
        header: "URL",
        width: 60,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    aoeTimeUpload: {
        header: "Upolad Time",
        width: 160,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    task: {
        header: "Track",
        width: 130,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    stride: {
        header: "Stride",
        width: 100,
        higherBetter: undefined,
        type: "number",
    },
    inputFormat: {
        header: "Input Format",
        width: 100,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    corpus: {
        header: "Corpus",
        width: 100,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    paramDesc: {
        header: "Parameter Description",
        width: 100,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    paramShared: {
        header: "Params",
        width: 130,
        higherBetter: false,
        type: "number",
    },
    fineTunedParam: {
        header: "Fine-tuned parameters",
        width: 100,
        higherBetter: undefined,
        type: "number",
    },
    taskSpecParam: {
        header: "Task-specific parameters",
        width: 100,
        higherBetter: undefined,
        type: "number",
    },
};

const leaderboard_10m_columnInfo = {
    ...leaderboard_columnInfo,
    ...public_task_columnInfo_10m,
}

const leaderboard_1h_columnInfo = {
    ...leaderboard_columnInfo,
    ...public_task_columnInfo_1h,
}

const leaderboard_hidden_10m_columnInfo = {
    ...leaderboard_columnInfo,
    ...hidden_task_columnInfo_10m,
}

const leaderboard_hidden_1h_columnInfo = {
    ...leaderboard_columnInfo,
    ...hidden_task_columnInfo_1h,
}

const leaderboard_hidden_columnInfo = {
    submitName: {
        header: "Method",
        width: 150,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    name: {
        header: "Name",
        width: 100,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    modelDesc: {
        header: "Description",
        width: 100,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    aoeTimeUpload: {
        header: "Upolad Time",
        width: 160,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    task: {
        header: "Track",
        width: 130,
        higherBetter: undefined,
        type: "alphanumeric",
    },
    paramShared: {
        header: "Params",
        width: 130,
        higherBetter: false,
        type: "number",
    },
    ...hidden_task_columnInfo,
};

const reference_points = {
    // public
    "PR_per_public": [82.01, 3.53],
    "KS_acc_public": [41.3826674, 96.66],
    "IC_acc_public": [9.649354219, 98.76],
    "SID_acc_public": [20.058174, 90.3],
    "ER_acc_public": [48.23672168, 67.62],
    "ASR_wer_public": [23.18, 3.62],
    "QbE_mtwv_public": [0.58, 7.36],
    "SF_f1_public": [69.64, 89.81],
    "SF_cer_public": [52.94, 21.76],
    "SV_eer_public": [9.56, 5.11],
    "SD_der_public": [10.05, 5.62],
    "ST_bleu_public": [2.32, 20.01],
    "SE_pesq_public": [2.55, 2.64],
    "SE_stoi_public": [93.6, 94.2],
    "SS_sisdri_public": [9.2341, 10.4514],
    // hidden dev
    "PR_per_hidden_dev": [81.00076941, 16.32352551],
    "SID_acc_hidden_dev": [49.58333373, 79.83333468],
    "ER_acc_hidden_dev": [47.12328911, 67.94520617],
    "ASR_wer_hidden_dev": [90.89395359, 24.86784264],
    "SV_eer_hidden_dev": [25.5671, 12.7294],
    "SD_der_hidden_dev": [15.7551825, 10.48149392],
    "QbE_map_hidden_dev": [18.60194802, 51.08585358],
    "QbE_eer_hidden_dev": [36.94903255, 17.80432165],
    "ST_bleu_hidden_dev": [3.2, 23.33],
    "SS_sisdri_hidden_dev": [4.655592075, 8.082589958],
    "SE_pesq_hidden_dev": [1.510035692, 1.567159144],
    "SE_stoi_hidden_dev": [84.33188677, 85.20344653],
    // hidden test
    "PR_per_hidden_test": [81.663806713, 18.215388839],
    "SID_acc_hidden_test": [48.166665435, 80.250000954],
    "ER_acc_hidden_test": [46.978020668, 60.989010334],
    "ASR_wer_hidden_test": [91.54207604, 27.05541676],
    "SV_eer_hidden_test": [24.0449, 9.81481],
    "SD_der_hidden_test": [13.4, 9.1],
    "QbE_map_hidden_test": [12.724326551, 49.059066176],
    "QbE_eer_hidden_test": [35.976561904, 16.548413038],
    "ST_bleu_hidden_test": [2.32, 20.01],
    "SS_sisdri_hidden_test": [2.845464051, 7.301311103],
    "SE_pesq_hidden_test": [1.530031757, 1.5694],
    "SE_stoi_hidden_test": [84.461450984, 85.292513083],
    
}

const hidden_dev_set = [
    "macs", "macsShort", "macsMedium", "macsLong", "macsLonger",
    "PR_per_hidden_dev", "SID_acc_hidden_dev", "ER_acc_hidden_dev", "ASR_wer_hidden_dev", "QbE_map_hidden_dev",
    "QbE_eer_hidden_dev", "SV_eer_hidden_dev", "SD_der_hidden_dev", "ST_bleu_hidden_dev",
    "SE_pesq_hidden_dev", "SE_stoi_hidden_dev", "SS_sisdri_hidden_dev",
]

const hidden_test_set = [
    "macs", "macsShort", "macsMedium", "macsLong", "macsLonger",
    "PR_per_hidden_test", "SID_acc_hidden_test", "ER_acc_hidden_test", "ASR_wer_hidden_test", "QbE_map_hidden_test",
    "QbE_eer_hidden_test", "SV_eer_hidden_test", "SD_der_hidden_test", "ST_bleu_hidden_test",
    "SE_pesq_hidden_test", "SE_stoi_hidden_test", "SS_sisdri_hidden_test",
]

const leaderboard_data = [
    {
        submitName: "HuBERT Base",
        modelURL: "https://ieeexplore.ieee.org/abstract/document/9585401",
        ASR_mono_public_10m: 42.8,
        ASR_multi_norm_public_10m: 39.8,
        ASR_multi_few_public_10m: 44.5,
        LID_public_10m: 61.2,
        ASR_LID_multi_norm_asr_public_10m: 39.2,
        ASR_LID_multi_norm__lid_public_10m: 71.5,
        ASR_LID_multi_few_public_10m:43.8,
        ASR_mono_public_1h: 35.3,
        ASR_multi_norm_public_1h: 31.4,
        ASR_multi_few_public_1h: 42.7,
        LID_public_1h: 86.1,
        ASR_LID_multi_norm_asr_public_1h: 86.0,
        ASR_LID_multi_norm__lid_public_1h: 30.9,
        ASR_LID_multi_few_public_1h:41.8,
        ASR_mono_hidden_10m: 61.1,
        ASR_multi_norm_hidden_10m: 54.9,
        LID_hidden_10m: 47.5,
        ASR_LID_multi_norm_asr_hidden_10m: 53.6,
        ASR_LID_multi_norm__lid_hidden_10m: 46.8,
        ASR_mono_hidden_1h: 53.8,
        ASR_multi_norm_hidden_1h: 47.2,
        LID_hidden_1h: 65.1,
        ASR_LID_multi_norm_asr_hidden_1h: 45.8,
        ASR_LID_multi_norm__lid_hidden_1h: 63.1,
    },
    {
        submitName: "HuBERT Large",
        modelURL: "https://ieeexplore.ieee.org/abstract/document/9585401",
        ASR_mono_public_10m: 38.2,
        ASR_multi_norm_public_10m: 44.4,
        ASR_multi_few_public_10m: 48.2,
        LID_public_10m: 46.5,
        ASR_LID_multi_norm_asr_public_10m: 45.6,
        ASR_LID_multi_norm__lid_public_10m: 55.4,
        ASR_LID_multi_few_public_10m:49.3,
        ASR_mono_public_1h: 32.2,
        ASR_multi_norm_public_1h: 37.7,
        ASR_multi_few_public_1h: 43.5,
        LID_public_1h: 64.1,
        ASR_LID_multi_norm_asr_public_1h: 77.7,
        ASR_LID_multi_norm__lid_public_1h: 35.1,
        ASR_LID_multi_few_public_1h:42.2,
        ASR_mono_hidden_10m: 62.7,
        ASR_multi_norm_hidden_10m: 54.3,
        LID_hidden_10m: 44.4,
        ASR_LID_multi_norm_asr_hidden_10m: 53.3,
        ASR_LID_multi_norm__lid_hidden_10m: 43.2,
        ASR_mono_hidden_1h: 52.3,
        ASR_multi_norm_hidden_1h: 47.3,
        LID_hidden_1h: 57.9,
        ASR_LID_multi_norm_asr_hidden_1h: 45.0,
        ASR_LID_multi_norm__lid_hidden_1h: 58.9,

    },
    {
        submitName: "Mandarin HuBERT Base",
        modelURL: "https://github.com/TencentGameMate/chinese_speech_pretrain",
        ASR_mono_public_10m: 43.1,
        ASR_multi_norm_public_10m: 40.8,
        ASR_multi_few_public_10m: 45.4,
        LID_public_10m: 49.3,
        ASR_LID_multi_norm__lid_public_10m: 75.1,
        ASR_LID_multi_norm_asr_public_10m: 37.7,
        ASR_LID_multi_few_public_10m:43.5,
        ASR_mono_public_1h: 45.6,
        ASR_multi_norm_public_1h: 43.2,
        ASR_multi_few_public_1h: 46.6,
        LID_public_1h: 85.3,
        ASR_LID_multi_norm_asr_public_1h: 86.1,
        ASR_LID_multi_norm__lid_public_1h: 31.8,
        ASR_LID_multi_few_public_1h:42.1,
    },
    {
        submitName: "Mandarin HuBERT Large",
        modelURL: "https://github.com/TencentGameMate/chinese_speech_pretrain",
        ASR_mono_public_10m: 39.4,
        ASR_multi_norm_public_10m: 42.6,
        ASR_multi_few_public_10m: 45.8,
        LID_public_10m: 39.5,
        ASR_LID_multi_norm__lid_public_10m: 66.4,
        ASR_LID_multi_norm_asr_public_10m: 41.9,
        ASR_LID_multi_few_public_10m:45.2,
        ASR_mono_public_1h: 33.7,
        ASR_multi_norm_public_1h: 39.6,
        ASR_multi_few_public_1h: 45.1,
        LID_public_1h: 57.3,
        ASR_LID_multi_norm_asr_public_1h: 75.6,
        ASR_LID_multi_norm__lid_public_1h: 37.1,
        ASR_LID_multi_few_public_1h:44.4,
    },
    {
        submitName: "mHuBERT",
        modelURL: "https://www.isca-speech.org/archive/pdfs/interspeech_2022/popuri22_interspeech.pdf",
        ASR_mono_public_10m: 41.0,
        ASR_multi_norm_public_10m: 40.5,
        ASR_multi_few_public_10m: 45.6,
        LID_public_10m: 52.4,
        ASR_LID_multi_norm__lid_public_10m: 46.6,
        ASR_LID_multi_norm_asr_public_10m: 36.8,
        ASR_LID_multi_few_public_10m: 44.2,
        ASR_mono_public_1h: 33.0,
        ASR_multi_norm_public_1h: 33.4,
        ASR_multi_few_public_1h: 43.6,
        LID_public_1h: 72.5,
        ASR_LID_multi_norm_asr_public_1h: 70.9,
        ASR_LID_multi_norm__lid_public_1h: 29.75,
        ASR_LID_multi_few_public_1h:43.1,
        ASR_mono_hidden_10m: 59.8,
        ASR_multi_norm_hidden_10m: 53.2,
        LID_hidden_10m: 45.2,
        ASR_LID_multi_norm_asr_hidden_10m: 52.7,
        ASR_LID_multi_norm__lid_hidden_10m: 44.0,
        ASR_mono_hidden_1h: 53.3,
        ASR_multi_norm_hidden_1h: 46.1,
        LID_hidden_1h: 65.3,
        ASR_LID_multi_norm_asr_hidden_1h: 45.5,
        ASR_LID_multi_norm__lid_hidden_1h: 61.6,

    },
    {
        submitName: "wav2vec 2.0 Base",
        modelURL: "https://proceedings.neurips.cc/paper/2020/hash/92d1e1eb1cd6f9fba3227870bb6d7f07-Abstract.html",
        ASR_mono_public_10m: 44.2,
        ASR_multi_norm_public_10m: 43.0,
        ASR_multi_few_public_10m: 45.7,
        LID_public_10m: 54.4,
        ASR_LID_multi_norm__lid_public_10m: 66.9,
        ASR_LID_multi_norm_asr_public_10m: 40.6,
        ASR_LID_multi_few_public_10m:44.2,
        ASR_mono_public_1h: 35.9,
        ASR_multi_norm_public_1h: 35.5,
        ASR_multi_few_public_1h: 44.3,
        LID_public_1h: 80.8,
        ASR_LID_multi_norm_asr_public_1h: 83.6,
        ASR_LID_multi_norm__lid_public_1h: 32.1,
        ASR_LID_multi_few_public_1h:42.68,
        ASR_mono_hidden_10m: 62.7,
        ASR_multi_norm_hidden_10m: 55.0,
        LID_hidden_10m: 43.0,
        ASR_LID_multi_norm_asr_hidden_10m: 54.3,
        ASR_LID_multi_norm__lid_hidden_10m: 47.0,
        ASR_mono_hidden_1h: 54.2,
        ASR_multi_norm_hidden_1h: 47.0,
        LID_hidden_1h: 60.2,
        ASR_LID_multi_norm_asr_hidden_1h: 42.6,
        ASR_LID_multi_norm__lid_hidden_1h: 42.2,

    },
    {
        submitName: "wav2vec 2.0 Large",
        modelURL: "https://proceedings.neurips.cc/paper/2020/hash/92d1e1eb1cd6f9fba3227870bb6d7f07-Abstract.html",
        ASR_mono_public_10m: 42.0,
        ASR_multi_norm_public_10m: 42.6,
        ASR_multi_few_public_10m: 45.8,
        LID_public_10m: 30.9,
        ASR_LID_multi_norm__lid_public_10m: 54.6,
        ASR_LID_multi_norm_asr_public_10m: 45.5,
        ASR_LID_multi_few_public_10m:50.3,
        ASR_mono_public_1h: 35.4,
        ASR_multi_norm_public_1h: 35.7,
        ASR_multi_few_public_1h: 43.9,
        LID_public_1h: 8.0,
        ASR_LID_multi_norm_asr_public_1h: 78.2,
        ASR_LID_multi_norm__lid_public_1h: 34.7,
        ASR_LID_multi_few_public_1h:42.2,
    },
    {
        submitName: "Robust wav2vec 2.0 Large",
        modelURL: "https://www.isca-speech.org/archive/interspeech_2021/hsu21_interspeech.html",
        ASR_mono_public_10m: 44.4,
        ASR_multi_norm_public_10m: 40.1,
        ASR_multi_few_public_10m: 45.4,
        LID_public_10m: 50.8,
        ASR_LID_multi_norm__lid_public_10m: 33.1,
        ASR_LID_multi_norm_asr_public_10m: 38.6,
        ASR_LID_multi_few_public_10m:44.9,
        ASR_mono_public_1h: 35.7,
        ASR_multi_norm_public_1h: 31.1,
        ASR_multi_few_public_1h: 42.2,
        LID_public_1h: 72.1,
        ASR_LID_multi_norm_asr_public_1h: 62.9,
        ASR_LID_multi_norm__lid_public_1h: 33.7,
        ASR_LID_multi_few_public_1h:46.0,

    },
    {
        submitName: "wav2vec 2.0 Base 23",
        modelURL: "https://aclanthology.org/2021.acl-long.80/",
        ASR_mono_public_10m: 49.2,
        ASR_multi_norm_public_10m: 37.7,
        ASR_multi_few_public_10m: 43.4,
        LID_public_10m: 58.7,
        ASR_LID_multi_norm__lid_public_10m: 45.1,
        ASR_LID_multi_norm_asr_public_10m: 37.2,
        ASR_LID_multi_few_public_10m:44.3,
        ASR_mono_public_1h: 35.1,
        ASR_multi_norm_public_1h: 32.0,
        ASR_multi_few_public_1h: 42.2,
        LID_public_1h: 71.9,
        ASR_LID_multi_norm_asr_public_1h: 66.3,
        ASR_LID_multi_norm__lid_public_1h: 30.9,
        ASR_LID_multi_few_public_1h:43.0,
        ASR_mono_hidden_10m: 63.7,
        ASR_multi_norm_hidden_10m: 55.4,
        LID_hidden_10m: 44.1,
        ASR_LID_multi_norm_asr_hidden_10m: 55.0,
        ASR_LID_multi_norm__lid_hidden_10m: 48.8,
        ASR_mono_hidden_1h: 56.1,
        ASR_multi_norm_hidden_1h: 49.9,
        LID_hidden_1h: 57.0,
        ASR_LID_multi_norm_asr_hidden_1h: 48.5,
        ASR_LID_multi_norm__lid_hidden_1h: 59.9,

    },
    {
        submitName: "wav2vec 2.0 Large 23",
        modelURL: "https://aclanthology.org/2021.acl-long.80/",
        ASR_mono_public_10m: 42.0,
        ASR_multi_norm_public_10m: 42.1,
        ASR_multi_few_public_10m: 44.3,
        LID_public_10m: 1.1,
        ASR_LID_multi_norm__lid_public_10m: 21.8,
        ASR_LID_multi_norm_asr_public_10m: 43.4,
        ASR_LID_multi_few_public_10m:46.1,
        ASR_mono_public_1h: 34.2,
        ASR_multi_norm_public_1h: 35.3,
        ASR_multi_few_public_1h: 42.4,
        LID_public_1h: 64.2,
        ASR_LID_multi_norm_asr_public_1h: 49.7,
        ASR_LID_multi_norm__lid_public_1h: 35.2,
        ASR_LID_multi_few_public_1h:43.1,
    },
    {
        submitName: "XLSR 53",
        modelURL: "https://www.isca-speech.org/archive/pdfs/interspeech_2021/conneau21_interspeech.pdf?ref=ruder-io",
        ASR_mono_public_10m: 49.5,
        ASR_multi_norm_public_10m: 33.9,
        ASR_multi_few_public_10m: 43.6,
        LID_public_10m: 6.6,
        ASR_LID_multi_norm__lid_public_10m: 45.6,
        ASR_LID_multi_norm_asr_public_10m: 33.4,
        ASR_LID_multi_few_public_10m:43.2,
        ASR_mono_public_1h: 34.9,
        ASR_multi_norm_public_1h: 26.9,
        ASR_multi_few_public_1h: 40.6,
        LID_public_1h: 87.1,
        ASR_LID_multi_norm_asr_public_1h: 76.9,
        ASR_LID_multi_norm__lid_public_1h: 28.6,
        ASR_LID_multi_few_public_1h:44.6,
    },
    {
        submitName: "XLS-R 128 300m",
        modelURL: "https://www.isca-speech.org/archive/pdfs/interspeech_2022/babu22_interspeech.pdf",
        ASR_mono_public_10m: 39.7,
        ASR_multi_norm_public_10m: 29.2,
        ASR_multi_few_public_10m: 40.9,
        LID_public_10m: 66.9,
        ASR_LID_multi_norm__lid_public_10m: 55.6,
        ASR_LID_multi_norm_asr_public_10m: 28.4,
        ASR_LID_multi_few_public_10m:42.1,
        ASR_mono_public_1h: 30.6,
        ASR_multi_norm_public_1h: 22.0,
        ASR_multi_few_public_1h: 39.3,
        LID_public_1h: 87.9,
        ASR_LID_multi_norm_asr_public_1h: 85.6,
        ASR_LID_multi_norm__lid_public_1h: 22.9,
        ASR_LID_multi_few_public_1h:42.4,
        ASR_mono_hidden_10m: 57.6,
        ASR_multi_norm_hidden_10m: 47.9,
        LID_hidden_10m: 48.4,
        ASR_LID_multi_norm_asr_hidden_10m: 47.0,
        ASR_LID_multi_norm__lid_hidden_10m: 51.2,
        ASR_mono_hidden_1h: 49.2,
        ASR_multi_norm_hidden_1h: 39.0,
        LID_hidden_1h: 70.2,
        ASR_LID_multi_norm_asr_hidden_1h: 37.7,
        ASR_LID_multi_norm__lid_hidden_1h: 70.7,

    }
]

export {
    reference_points,
    subscribe_link,
    domains,
    tracks,
    individual_submission_columnInfo,
    individual_submission_hidden_columnInfo,
    leaderboard_columnInfo,
    leaderboard_hidden_columnInfo,
    leaderboard_selections,
    public_hidden_selections,
    hidden_dev_set,
    hidden_test_set,
    leaderboard_10m_columnInfo,
    leaderboard_1h_columnInfo,
    leaderboard_hidden_10m_columnInfo,
    leaderboard_hidden_1h_columnInfo,
    leaderboard_data
};
