import React, { useState } from "react";
import { HashLink } from 'react-router-hash-link';
import Clock from "react-live-clock";

import { Typography } from "@material-ui/core";
import { useTheme, createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";

import { submitFormTheme } from "./Theme";
import { SubSubSection } from "./Sections";
import { SubTitle } from "./Titles";
import { Strong } from "./Utilies";
import PubHidSelect from "./PubHidSelect";
import PublicForm from "./PublicForm";
import HiddenForm from "./HiddenForm";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function SubmitForm(props) {
    const theme = useTheme();
    let query = useQuery();
    const [type, setType] = useState(query.get("type") || "public");

    const onTypeChange = (e) => {
        setType(e.target.value);
        const url = new URL(window.location);
        url.searchParams.set("type", e.target.value);
        window.history.pushState({}, '', url);
    };

    const SwitchPart = () => {
        switch(type) {
            case "public":   return <PublicForm />;
            case "hidden":   return <HiddenForm />;
            default:      return <h1>No competition type match</h1>
        }
      }

    return (
        <ThemeProvider theme={createMuiTheme(submitFormTheme)}>
            <>
                <SubSubSection margin={theme.spacing(8, "auto", 1)}>
                    <SubTitle
                        title={<span><strong>Submission</strong></span>
                        }
                        description={
                            <Clock
                                format={"YYYY-MM-DD HH:mm:ss"}
                                ticking={true}
                                timezone={"Etc/GMT+12"}
                            />
                        }
                        titleColor="primary"
                    />
                </SubSubSection>
                {props.login ? (
                    <>
                        <PubHidSelect type={type} onTypeChange={onTypeChange} />
                        <SwitchPart />
                    </>
                ) : (
                    <>
                        <SubSubSection>
                            <Typography variant="body1" color="textSecondary">
                                The submission system for ML-SUPERB is now open! The instructions for each track are below. 
                                For all tracks, all papers should be submitted to <a href={"http://www.asru2023.org/motion.asp?siteid=1007526&menuid=49654&postid=697218"} target="_blank" rel="noopener noreferrer">ASRU 2023</a>. Please make sure to select SS02. ML-SUPERB as the subject area.
                                Submitted papers should follow the ASRU guidelines.
                            </Typography>
                        </SubSubSection>
                        <SubSubSection>
                            <Typography variant="body1" color="textSecondary">
                                The submission system for the ML-SUPERB Track 1 is available <a href={"https://forms.gle/GMnzmSDy2AP8V2Sr8"} target="_blank" rel="noopener noreferrer">here</a>. An (optional) accompanying system description paper can also be submitted to ASRU.
                            </Typography>
                        </SubSubSection>
                        <SubSubSection>
                            <Typography variant="body1" color="textSecondary">
                                The submission system for the ML-SUPERB New Language Track (Track 2) is available <a href={"https://forms.gle/Vcd3XJLz1cZ2zNMy6"} target="_blank" rel="noopener noreferrer">here</a>. A data description paper is also required to be submitted to ASRU.
                            </Typography>
                        </SubSubSection>
                        <SubSubSection>
                            <Typography variant="body1" color="textSecondary">
                                The ML-SUPERB Research Paper Track (Track 3) only requires a paper submission to ASRU.
                            </Typography>
                        </SubSubSection>
                    </>
                )}
            </>
        </ThemeProvider>
    );
}
